<template>
    <div class="bg-home overflow-hidden"
        style="background: url('assets/images/hotspot/image_1.png') center no-repeat fixed; background-size: cover; margin: 0; padding: 0; -webkit-background-size: cover;">
        <header id="topnav" class="defaultscroll sticky">
            <div id="navigation">
                <router-link class="logo logo_cust" to="/">
                    <img src="/assets/images/logo.png" style="width: 189px; height: 48px" alt="">
                </router-link>
            </div>
        </header>

        <section class="app-content content bg-half-100 d-table w-100">
            <div class="row">
                <div class="col-lg-6 col-md-6 mx-auto">
                    <div class="card login-page bg-white shadow rounded-lg border-0 mt-4 p-4">
                        <div class="card-body">
                            <h4 class="card-title text-center">Connexion</h4>
                            <form class="login-form mt-4">
                                <div class="row justify-content-center">
                                    <div class="col-lg-9">
                                        <div class="mb-3">
                                            <div class="form-icon position-relative">
                                                <i class="fea icon-sm icons fa fa-user"></i>
                                                <input name="name" id="name" type="text" class="form-control ps-5"
                                                    placeholder="Nom d'utilisateur">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-9">
                                        <div class="mb-3">
                                            <div class="form-icon position-relative">
                                                <i class="fea icon-sm icons fa fa-key"></i>
                                                <input name="code" id="code" type="text" class="form-control ps-5"
                                                    placeholder="Code de connexion">
                                            </div>
                                        </div>
                                    </div>
                                    <!--end col-->
                                    <div class="col-lg-9 mb-0">
                                        <div class="d-grid">
                                            <button class="btn btn-outline-primary rounded  mb-3">Connectez-vous
                                            </button>
                                            <router-link to="/forfait" class="btn btn-primary rounded  btn-of-primary">
                                                Achetez un
                                                pass</router-link>
                                        </div>
                                    </div>
                                    <div class="col-12 text-center mt-3 pt-3">
                                        <p class="mb-0 mt-3" style="font-size: 13px;"><small class="text-dark me-2">Si
                                                vous
                                                avez
                                                déjà
                                                souscrit sans recevoir vos identifiants, <br>
                                            </small>
                                            <!-- <router-link to="/recuperation" class="text-dark fw-bold">
                                                <u>récuperez-les
                                                    ici</u>
                                            </router-link> -->
                                        </p>
                                    </div>
                                    <!--end col-->
                                </div>
                                <!--end row-->
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="padding-top: 50px;">
                <div class="col-lg-6 mx-auto">
                    <div class="text-sm text-center text-light">
                        Copyright © - wigui | Tous droits réservés
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'Login',

        setup() {
            //
        }

    }
</script>

<style>
    .btn-outline-success {
        /* color: #2eca8b; */
        color: black;
        border-color: #2eca8b;
    }

    .btn-of-success:hover {
        background-color: white;
        color: black;
    }

    .bg-home {
        margin: 0;
        padding: 0;
        background: url();
    }

    .custom-color {
        color: #707070;
        text-transform: uppercase;
    }

    #topnav.nav-sticky {
        background: #007ABD;
    }

    @media (max-width: 991px) {
        #topnav {
            background: #007ABD;
        }
    }
</style>